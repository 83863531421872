var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "keep-alive",
            {
              attrs: {
                exclude: [
                  "AlbumPhotoBodyMain",
                  "AlbumPhotoBodyMain",
                  "RentalVenueSpaceMain",
                  "ExhibitionMain"
                ]
              }
            },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }