<template>
  <div>
    <transition name="fade" >
      <keep-alive :exclude="['AlbumPhotoBodyMain','AlbumPhotoBodyMain','RentalVenueSpaceMain', 'ExhibitionMain']">
        <router-view></router-view>
      </keep-alive>
    </transition>



  </div>
</template>

<script>
import commonTw from "components/DefaultHome/KECC/Common/lang/zh-tw.json";
import commonEn from "components/DefaultHome/KECC/Common/lang/en-us.json";
export default {
  name: "App",
  metaInfo() {
    return {
      title: `${this.$t("headTitle")}`,
      titleTemplate: `${this.$t("headTitle")} - %s`,
      meta: [
        {
          name: "google-site-verification",
          content: "OfijP-bbA1CO2W4MCwcApzfvCUNCLVDMPHGL4ANeit8",
        },
        {
          name: "msvalidate.01",
          content: "55A78E89DEDFC85D4554648B2F93D548",
        },
        {
          property: "og:url",
          content: `${window.location.protocol}//${window.location.host}`,
        },
        {
          property: "og:image",
          content: `${window.location.protocol}//${window.location.host}/no-picture-new.jpg`,
        },
        {
          property: "og:description",
          content: this.$t("headDesc"),
        },
        {
          name: "description",
          content: this.$t("headDesc"),
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0",
        },
        {
          name: "format-detection",
          content: "telephone=no",
        },
        {
          name: "author",
          content: "CTeam",
        },
        {
          name: "copyright",
          content: "高雄展覽館",
        },
        {
          "http-equiv": "content-language",
          content: `${this.$i18n.apiLang}`,
        },
        {
          "http-equiv": "X-UA-Compatible",
          content: "IE=edge,chrome=1",
        },
        {
          name: "keywords",
          content: `${this.$t("keyWord")}`,
        },
      ],
    };
  },
  data() {
    return {
      isChildComponentsLoaded: false,
      firstLoad: true
    }
  },
  created() {
    //使用 afterEach 原因為 router 為動態 import
    this.$router.afterEach((to) => {
      if (this.firstLoad) {
        this.loadlang(to); //第一次加載頁面，必須無延遲載入語言包
        this.firstLoad = false;
      }
      setTimeout(() => {
        this.loadlang(to);
      }, 0); // 配合過度動畫時間，延遲切換語言包

    });
    // this.$store.commit('removeKeepPages', 'xxx') // xxx为要销毁的页面名称
  },
  methods: {
    loadlang(to) {
      const mainPath = to.meta.componentRootPath;

      const lang_tw = Object.assign(
        {},
        commonTw,
        require(`components/DefaultHome/KECC${mainPath}/lang/zh-tw.json`)
      );
      const lang_en = Object.assign(
        {},
        commonEn,
        require(`components/DefaultHome/KECC${mainPath}/lang/en-us.json`)
      );
      this.$i18n.setLocaleMessage("zh-tw", lang_tw);
      this.$i18n.setLocaleMessage("en-us", lang_en);

      let currentLang = to.params.language;
      if (
        (currentLang != undefined && currentLang == "en-us") ||
        currentLang == "zh-tw"
      ) {
        this.$i18n.locale = currentLang;
      } else {
        const deviceLang = (
          navigator.browserLanguage || navigator.language
        ).toLowerCase();
        const deviceLangReg = new RegExp("en", "gm");
        if (deviceLangReg.test(deviceLang)) {
          this.$i18n.locale = "en-us";
        }
      }
      switch (this.$i18n.locale) {
        case "en-us":
          this.$i18n.apiLang = "en-US";
          break;
        case "zh-tw":
          this.$i18n.apiLang = "zh-TW";
          break;
        default:
          this.$i18n.apiLang = "zh-TW";
          break;
      }
      this.$i18n.bindLang = function (path, language) {
        //統一去除掉現有的語系路徑
        //path = (path=="/") ? "" : path;
        if (currentLang != undefined) {
          path = path.replace(new RegExp("/" + currentLang, "gm"), "");
        }
        //沒特別指定就以當前語系為主
        let lang = currentLang;
        if (language != undefined) {
          lang = language;
        }
        return lang == undefined ? path : "/" + lang + path;
      };
    }
  },


};
</script>

<style src="assets/css/kecc.css"></style>
<style lang="css" src="assets/css/ckeditor.css"></style>