import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 載入語言

Vue.use(VueI18n)

var i18n = new VueI18n({
    globalInjection: true,
    locale: 'zh-tw', // 設定語言
    fallbackLocale: 'zh-tw', // 若選擇的語言缺少翻譯則退回的語言
    silentTranslationWarn: true,
    missing: (locale) => {
    if (locale === 'zh-tw') {
        return '載入中';
    }
    if (locale === 'en-us') {
        return 'Loading';
    }
    return '';
    }
})
export default i18n