// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/Flaticon.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/Flaticon.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/Flaticon.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/Flaticon.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/fonts/Flaticon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#Flaticon" });
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\n \tFlaticon icon font: Flaticon\n \tCreation date: 20/05/2020 17:24\n \t*/\n@font-face {\n  font-family: \"Flaticon\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\");\n  font-weight: normal;\n  font-style: normal;\n}\n@media screen and (-webkit-min-device-pixel-ratio: 0) {\n  @font-face {\n    font-family: \"Flaticon\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\");\n  }\n}\n[class^=flaticon-]:before, [class*=\" flaticon-\"]:before,\n[class^=flaticon-]:after, [class*=\" flaticon-\"]:after {\n  font-family: Flaticon;\n  font-style: normal;\n}\n\n.flaticon-bug:before {\n  content: \"\";\n}\n\n.flaticon-content:before {\n  content: \"\";\n}\n\n.flaticon-support:before {\n  content: \"\";\n}\n\n.flaticon-profile:before {\n  content: \"\";\n}\n\n.flaticon-website:before {\n  content: \"\";\n}\n\n.flaticon-cyber:before {\n  content: \"\";\n}\n\n.flaticon-cyber-security:before {\n  content: \"\";\n}\n\n.flaticon-cyber-security-1:before {\n  content: \"\";\n}\n\n.flaticon-order:before {\n  content: \"\";\n}\n\n.flaticon-scientist:before {\n  content: \"\";\n}\n\n.flaticon-anti-virus-software:before {\n  content: \"\";\n}\n\n.flaticon-technical-support:before {\n  content: \"\";\n}\n\n.flaticon-medal:before {\n  content: \"\";\n}", ""]);
// Exports
module.exports = exports;
